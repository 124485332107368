.u14-mainMenu {
	@include fadeOut($duration4);
	@include font(mainMenu);
	background-color: $colorBgModal;
	color: $colorLink;
	display: none;
	left: 0;
	min-height: 100vh;
	box-sizing: border-box;
	position: relative;
	right: 0;
	top: 0;
	z-index: 3; //10; //TODO: @sr check, move do config...

	&.u14-js-fixed {
		position: fixed;
	}

	&.u14-js-beforeActive {
		display: flex;
		will-change: opacity, visibility;
	}

	&.u14-js-duringActive {
		@include fadeIn();
	}
}
