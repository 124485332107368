.u14-header {
	@mixin headerBtnLine() {
		position: relative;

		&::after {
			background: currentColor;
			bottom: 1px;
			content: '';
			display: block;
			height: 1px;
			position: absolute;
			width: calc(100% - #{$letterspacingLink});
		}
	}

	@include ease(transform, $duration3);
	background-color: $colorBgBase;
	left: 0;
	position: fixed;
	width: 100%;
	z-index: 4;

	&.u14-js-collapsed:not(.u14-js-coveredByBooking) {
		transform: translateY(-100%);
	}

	[data-u14-current-context='booking'] &,
	[data-u14-current-context='menu'] & {
		border-bottom: solid $colorHeaderBorder 1px;
	}

	&__booking {
		@include font(header);
		@include u14-call2actions;
		@include headerBtnLine;

		color: $colorAlternative;
	}

	&__buttonGroup {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		position: absolute;
		right: $containerPaddingSmall;
		top: 50%;
		transform: translateY(-50%);

		@include media($columnsBreakpoint) {
			right: $containerPaddingLarge;
		}
	}

	&__buttonGroupLang {
		//height: 20px;
		display: block;

		margin-right: $base2;
		@include media($columnsBreakpoint) {
			margin-right: $base3;
		}
		// top: 50%;
		// transform: translateY(-50%);
		//display: flex;
		//flex-direction: row;
		//align-items: flex-start;
	}

	&__contact {
		@include font(header);
		@include u14-call2actions;
		@include headerBtnLine;

		color: $colorAlternative;
		// line-height: 1em;
		display: none;

		@include media($columnsBreakpoint) {
			display: block;
			margin-right: $base3;
		}
	}

	&__content {
		@include u14-container();
		align-items: center;
		display: flex;
		justify-content: flex-start;
		padding-bottom: $headerPaddingSmall;
		padding-top: $headerPaddingSmall;
		position: relative;

		@include media('>small') {
			justify-content: center;
		}

		@include media($columnsBreakpoint) {
			padding-top: $headerPaddingLarge;
			padding-bottom: $headerPaddingLarge;
		}
	}

	&__label {
		&--smallScreen {
			display: block;

			@include media($columnsBreakpoint) {
				display: none;
			}
		}

		&--largeScreen {
			display: none;

			@include media($columnsBreakpoint) {
				display: block;
			}
		}
	}

	&__logo {
		color: currentColor;
		display: inline-block;
		height: $logoHeightSmall;
		text-decoration: none;

		[data-type='svg'] {
			@include ease(height, $duration2);

			height: 100%;

			.u14-header:not(.u14-js-scrolled) & {
				@include media($columnsBreakpoint) {
					height: $logoHeightMedium;
				}
			}
		}

		&--main {
			margin-left: $base5;

			@include media('>small') {
				margin-left: 0;
			}
		}

		&--autograph {
			margin-right: $base2;
			display: none;

			@include media($largeBreakpoint) {
				display: inline-block;
			}
		}

		&--marriott {
			margin-left: $base2;
			display: none;

			@include media($largeBreakpoint) {
				display: inline-block;
			}
		}

		@include media($columnsBreakpoint) {
			height: $logoHeightLarge;
		}
	}

	&__mainMenuToggler {
		left: $containerPaddingSmall;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		@include media($columnsBreakpoint) {
			left: $containerPaddingLarge;
		}
	}
}
