.u14-block {
	@mixin textAlignAndCenterForSmallScreen($default) {
		text-align: $default;

		&.u14-block--textAlignAndCenterForSmallScreen {
			text-align: center;

			@include media($columnsBreakpoint) {
				text-align: $default;
			}
		}
	}

	@mixin conditionalContainer() {
		@include u14-container();

		.u14-block--hPositionBegin &,
		.u14-block--hPositionEnd & {
			margin-left: 0;
			margin-right: 0;
		}


		.u14-block--group > .u14-block__container > & {
			@include u14-undoContainer();

			@include media($columnsBreakpoint) {
				@include u14-container();
			}
		}

		.u14-blocks--nested .u14-block:not(.u14-block--delimited) & {
			@include media($columnsBreakpoint) {
				@include u14-undoContainer();
			}
		}
	}

	@mixin teaserModifierBLock() {
		&.u14-block--colorSchemeDefault {
			background-color: $colorBgBase;
		}

		&.u14-block--minHeightFixed {
			min-height: calc(#{$teaserMinHeight} - #{$headerHeightSmall});

			@include media($columnsBreakpoint) {
				min-height: calc(#{$teaserMinHeight} - #{$headerHeightLarge});
			}

			.u14-js-pageElevator & {
				@include media($columnsBreakpoint) {
					min-height: $teaserMinHeight;
				}
			}
		}
	}

	@mixin teaserFigureModifierBLock($modifier) {
		#{'.u14-block' + $modifier} & {
			height: auto;
			width: 100%;
			order: 1;
		}

		#{'.u14-block' + $modifier}:not(.u14-block--minHeightBg) & {
			bottom: 0;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
		}

		#{'.u14-block' + $modifier} .u14-block__container:not(.u14-block__container--emptyLink) + & {
			opacity: $teaserBgOpacity;
		}
	}

	display: flex;
	flex-direction: row;
	position: relative;


	.u14-blocks--nested > & {
		@include media($columnsBreakpoint) {
			@include dynamicColumnsModifiers($layoutGutterH);
		}
	}

	&--colorSchemeInverted {
		@include u14-fadeIn($delayShowAnim);
		background-color: $colorBgInverted;
		color: $colorInverted;
	}

	&--colorSchemeAlternative {
		@include u14-fadeIn($delayShowAnim);
		background-color: $colorBgAlternative;
	}

	&--facepattern {
		@include teaserModifierBLock();
	}

	&--hAlignBegin {
		@include textAlignAndCenterForSmallScreen(left);
	}

	&--hAlignEnd {
		@include textAlignAndCenterForSmallScreen(right);
	}

	&--hAlignCenter {
		text-align: center;
	}

	&--hAlignJustified {
		@include textAlignAndCenterForSmallScreen(justify);
	}

	&--image {
		// @include u14-fadeInOpacity;
		@include u14-fadeIn($delayShowAnim);
	}

	&--slideshow {
		overflow: hidden;
	}

	&--teaser {
		@include teaserModifierBLock();
	}


	&--map {
		.u14-block--layout &:nth-child(2) {
			@include media($columnsBreakpoint) {
				order: -1;
			}
		}
	}


	&__category {
		@include font(category);
		order: 1;
		position: relative;

		.u14-block__title + & {
			padding-bottom: $base2;
		}

		.u14-block--extraLeftMargin & {
			@include media($columnsBreakpoint) {
				padding-left: $base4;
			}
		}
	}


	&__container {
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		order: 2;
		padding-bottom: $blockVerticalSpacing;
		position: relative;
		width: 100%;
		z-index: 1;

		// delimited variants is used for blocks that have a background or borders
		// in this case we do not want the top space to collapse
		.u14-block--delimited > & {
			padding-top: $blockVerticalSpacing;
		}

		.u14-blocks--nested > .u14-block:last-child > & {
			padding-bottom: 0;

			@include media($columnsBreakpoint) {
				padding-bottom: $blockVerticalSpacing;
			}
		}

		// remove the vertical rithm when they are organized into a grid layout
		.u14-blocks--nested > .u14-block:not(.u14-block--delimited) > &,
		.u14-blocks--nested > .u14-block:not(.u14-block--delimited):last-child > & {
			@include media($columnsBreakpoint) {
				padding-bottom: 0;
			}
		}

		.u14-block--delimited + .u14-block:not(.u14-block--delimited) > & {
			padding-top: $blockVerticalSpacing;

			// the vertical spacing needs to be removed because it's already provided by the grid gutters
			.u14-blocks--nested > & {
				@include media($columnsBreakpoint) {
					padding-top: 0;
				}
			}
		}

		// special case: first block of the page
		// we use this specific selector because a generic :first-child could match also in case of nested blocks
		.u14-page > .u14-blocks > .u14-block:first-child > & {
			padding-top: $firstBlockTopSpacing;
		}


		.u14-block--hPositionBegin > & {
			align-items: flex-start;
		}

		.u14-block--hPositionCenter > & {
			align-items: center;
		}

		.u14-block--hPositionEnd > & {
			align-items: flex-end;
		}

		.u14-block--vAlignBegin > & {
			justify-content: flex-start;
		}

		.u14-block--vAlignEnd > & {
			justify-content: flex-end;
		}

		.u14-block--vAlignCenter > & {
			justify-content: center;
		}

		.u14-block--vAlignJustified > & {
			justify-content: flex-start;
			// the content will decide who is taking space and who is not
		}

		.u14-block--minHeightBg > & {
			transform: translateX(-100%);
		}

		.u14-block--minorBottomMarginForSmallScreen > & {
			padding-bottom: $base3;
		}

		.u14-block--narrowWidthForBigScreen > & {
			@include media($columnsBreakpoint) {
				width: 50%;
			}
		}
	}


	&__content {
		@include conditionalContainer();
		display: flex;
		flex-direction: column;
		order: 4;
		position: relative;
		z-index: 1;

		.u14-block--hSizeFull > .u14-block__container > & {
			@include media($columnsBreakpoint) {
				max-width: none;
			}
		}

		.u14-blocks:not(.u14-blocks--nested) > .u14-block--hSizeFull:not(.u14-block--group) & {
			@include u14-undoContainer();

			@include media($columnsBreakpoint) {
				@include u14-undoContainer();
			}
		}

		.u14-block--previews .u14-block__header + & {
			margin-top: $base3;

			@include media($columnsBreakpoint) {
				margin-top: $base6;
			}
		}

		.u14-block--slideshow & {
			padding-left: 0;
			padding-right: 0;

			@include media($columnsBreakpoint) {
				padding-left: $containerPaddingLarge;
				padding-right: $containerPaddingLarge;
			}
		}

		.u14-block--colorSchemeAlternative.u14-block--text & {
			@include u14-fadeUp($delayShowAnim);
		}

		.u14-blocks--nested .u14-block--text & {
			@include u14-fadeUp($delayShowAnim);
		}

		.u14-block--text & {
			@include media($columnsBreakpoint) {
				padding-left: staticColumnPush(1, 12, $gutter, $containerPaddingLarge);
				padding-right: staticColumnPush(1, 12, $gutter, $containerPaddingLarge);
			}
		}

		.u14-block--vAlignJustified & {
			flex-grow: 1;
		}

		* + & {
			margin-top: $base3;
		}
	}


	&__figure {
		z-index: 0;
		flex-grow: 0;
		flex-shrink: 0;

		@include teaserFigureModifierBLock('--facepattern');
		@include teaserFigureModifierBLock('--teaser');
	}


	&__header {
		@include conditionalContainer();
		display: flex;
		flex-direction: column;
		z-index: 1;

		.u14-block--colorSchemeAlternative.u14-block--text & {
			@include u14-fadeUp($delayShowAnim);
		}

		.u14-block--group > .u14-block__container > & {
			@include u14-container();
		}

		.u14-blocks--nested & {
			@include u14-fadeUp($delayShowAnim);
		}

		.u14-block--text & {
			@include media($columnsBreakpoint) {
				padding-left: staticColumnPush(1, 12, $gutter, $containerPaddingLarge);
				padding-right: staticColumnPush(1, 12, $gutter, $containerPaddingLarge);
			}
		}
	}


	&__subtitle {
		@include font(subtitle);
		order: 3;
		position: relative;

		* + & {
			margin-top: $base;
		}

		.u14-block--textSizeBigger & {
			@include font(subtitleBigger);
		}

		.u14-block--textSizeBiggerTitle & {
			@include font(subtitleBigger);
		}

		.u14-block--extraLeftMargin & {
			@include media($columnsBreakpoint) {
				padding-left: $base4;
			}
		}
	}


	&__title {
		@include font(title);
		order: 2;
		position: relative;

		.u14-block--extraLeftMargin & {
			@include media($columnsBreakpoint) {
				padding-left: $base4;
			}
		}

		.u14-block--previews & {
			@include font(titleBigger);
		}

		.u14-block--textSizeBigger & {
			@include font(titleBigger);
		}

		.u14-block--textSizeBiggerTitle & {
			@include font(titleBigger);
		}
	}
}
