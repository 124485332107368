
.u14-contact {
	$diameter: $base5;

	background: $colorBgAlternative;
	bottom: $containerPaddingSmall;
	color: $colorBase;
	line-height: 1em;
	position: fixed;
	right: $containerPaddingSmall;
	z-index: 3;
	border-radius: 50%;
	width: $diameter;
	height: $diameter;

	@include u14-hoverCondition {
		@include u14-hover;
	}

	@include media($columnsBreakpoint) {
		display: none;
	}


	[data-type="svg"] {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 75%;
	}
}
