$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base / 2;

$videoRatio: 16 / 9;

$containerPaddingSmall: $base2;
// $containerPaddingMedium: $base5;
$containerPaddingLarge: $base7;
$containerMaxWidth: 144 * $base;
$infoModalMaxWidth: 80 * $base;


$blockVerticalSpacing: $base * 12;//$base4;
$firstBlockTopSpacing: $base6;
$teaserMinHeight: 100vh;

$gutter: $base * 1.5;
$layoutGutterH: $base6;
$layoutGutterV: $base * 12;

$galleryJustifiedItemMinHeight: $base * 30;
$galleryColumnsNumber: 2;
$galleryColumnsImageRatio: 3 / 2;

$slideshowMaxItems: 20;

$slideshowTriggersMargin: $base3;
$slideshowTriggerDiameter: $base;

$slideMinHeight: 75vh;
$slideRatio: 16 / 9;
$slideGap: $gutter;

$letterspacingTitle: 0.1em;
$letterspacingLink: 0.1em;


$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.2;
$boxBorderSize: 2px;

$fieldsVSpace: $base2;

$formFieldFullColumns: 8; // number of columns, 12 = full width

$sizeVariants: (
);

$logoHeightSmall: $base * 1.5;
$logoHeightMedium: $base * 2;
$logoHeightLarge: $base * 2.8;
$headerPaddingSmall: $base3;
$headerPaddingLarge: $base * 2.5;
$headerHeightSmall: (2 * $headerPaddingSmall) + $logoHeightSmall;
$headerHeightLarge: (2 * $headerPaddingLarge) + $logoHeightLarge;


$mapHeightSmall: 50 * $base;
$mapHeightLarge: 50 * $base;
