.u14-langMenu {
	@include font(languageMenu);
	@include u14-container();

	align-items: flex-start;
	bottom: 1em;
	display: flex;
	flex-direction: column;
	left: 50%;
	position: absolute;
	transform: translate(-50%);


	&__item {
		//height: 20px;
		//align-self: flex-start;
		display: inline-block;
		white-space: nowrap;
		position: relative;

		.u14-header__buttonGroupLang & {
			margin-left: 10px;

			&::after {
				content: '/';
				@include font(languageMenu);
				color: $colorAlternative;
				display: inline-block;
			}

			&:last-of-type::after {
				content: '';
				display: none;
			}
		}
	}

	&__link {
		@include u14-call2actions;
		@include font(languageMenu);

		color: $colorHighlightDisabled;
		//flex-grow: 0;
		//flex-shrink: 1;

		&--current {
			color: $colorAlternative;
		}

	}

	&__linkLabel {
		@include u14-call2actions;
		@include font(languageMenu);

		color: $colorHighlightDisabled;
		//flex-grow: 0;
		//flex-shrink: 1;

		.u14-langMenu__item--current & {
			color: $colorAlternative;
		}
	}
}
