.u14-menu {
	.u14-footer & {
		border-top: solid currentColor 1px;
	}

	.u14-mainMenu & {
		@include u14-container();

		padding-top: $headerHeightSmall;
		display: flex;
		justify-content: center;
		flex-direction: column;

		@include media($columnsBreakpoint) {
			padding-top: $headerHeightLarge;
		}
	}


	&__item {
		.u14-footer & {
			@include media($columnsBreakpoint) {
				margin-right: $base3;
			}

			&:last-child {
				@include media($columnsBreakpoint) {
					margin-right: 0;
				}
			}
		}

		.u14-mainMenu & {
			@include media($columnsBreakpoint) {
				margin-right: 1em;
			}
		}
	}


	&__items {
		.u14-footer & {
			padding-bottom: $base2;
			padding-top: $base2;

			@include media($columnsBreakpoint) {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}
		}

		.u14-footer & & {
			padding-bottom: 0;
			padding-top: 0;

			@include media($columnsBreakpoint) {
				justify-content: flex-start;
			}
		}

		.u14-mainMenu & {
			position: relative;
			margin-bottom: 12 * $base;

			@include media($columnsBreakpoint) {
				margin-bottom: 3em;
				width: 50%;
			}
		}
	}


	&__link {
		@include u14-link;

		.u14-mainMenu & {
			display: block;
			padding-bottom: $base1;
			padding-top: $base1;
		}
	}

	&__linkImage {
		$imgLeftSpaceColumnFraction: 3 / 4;

		@include ease(opacity, $duration4, $easeOutQuad, $duration);

		display: none;
		height: 100%;
		// left: staticColumnPush($imgLeftSpaceColumnFraction, 12, $gutter, 100%);
		left: 50vw;
		opacity: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		width: 50vw;
		z-index: 1;

		@include media($columnsBreakpoint) {
			display: block;
		}

		.u14-menu__link:hover & {
			@include ease(opacity, $duration4, $easeOutQuad);

			@include media($columnsBreakpoint) {
				display: block;
				opacity: 1;
				z-index: 2;
			}
		}
	}


	&__linkLabel {
		&--autograph {
			[data-type="svg"] {
				position: relative;
				top: $base * 1.2;
			}
		}

		&--marriott {
			[data-type="svg"] {
				position: relative;
				top: 0;
			}
		}
	}


	&__sectionTitle {
		.u14-footer & {
			display: none;
		}
	}
}
