// refer to _static-columns.scss for documentation

// $dir is the direction of the push: left OR right
// $size is the width in number of columns
// $total is the max number of columns supported by the grid
// $hSpace is the gutter
@function staticColumnPush($size, $total, $hSpace, $extra: '0px') {
	$hSpace: if($hSpace == 0, unquote('0px'), $hSpace);
	@return calc(#{100% * $size / $total} + #{($size / $total) * $hSpace} + #{$extra});
}
