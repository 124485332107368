@mixin u14-fadeUp($delay: 0s, $element: false) {
	@if ($element == false) {
		&[data-u14-intersect] {
			opacity: 0;
			transform: translateY($baseYShift);
		}

		&.u14-js-inside {
			@include ease(opacity transform, $durationShowAnim, $easeOutQuad, $delay);
			opacity: 1;
			transform: translateY(0);
		}
	}
	@else {
		#{$element}[data-u14-intersect] & {
			opacity: 0;
			transform: translateY($baseYShift);
		}

		#{$element}.u14-js-inside & {
			@include ease(opacity transform, $durationShowAnim, $easeOutQuad, $delay);
			opacity: 1;
			transform: translateY(0);
		}
	}
}
