@mixin u14-link($withHover: true) {
	@include ease(color, $hoverDuration);

	color: $colorLink;
	font-family: $fontFamilyAlternative;
	font-variant-ligatures: none;
	letter-spacing: $letterspacingLink;
	text-decoration: none;
	text-transform: lowercase;


	@if ($withHover == true) {
		@include u14-hoverCondition {
			@include u14-hover;
		}
	}
}
