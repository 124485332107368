.u14-button {
	@include button();
	@include font(field);
	background-color: $colorAlternative;
	border-color: $colorAlternative;
	border-style: solid;
	border-width: $buttonBorderSize;
	color: $colorInverted;
	padding: $inputPadding;
	text-align: center;

	&:disabled,
	&.u14-js-disabled {
		cursor: default;
		opacity: $opacityInputDisabled;
		pointer-events: none;
	}

	&--infoModal {
		border: 0;
		min-width: 100%;
		text-transform: uppercase;

		@include media($columnsBreakpoint) {
			min-width: $base * 20;
		}

		& + & {
			margin-top: $base2;

			@include media($columnsBreakpoint) {
				margin-left: $base2;
				margin-top: 0;
			}
		}
	}

	&--closeInfoModal {
		background-color: transparentize($colorAlternative, 0.5);
	}

	&--arrow {
		@include u14-link;
		background-color: transparent;
		border: 0;
		fill: currentColor;
		padding: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.u14-button--prev {
			justify-content: flex-start;

			@include media($columnsBreakpoint) {
				left: $containerPaddingLarge;
			}
		}

		&.u14-button--next {
			justify-content: flex-end;

			@include media($columnsBreakpoint) {
				right: $containerPaddingLarge;
			}
		}
	}

	&--slideshowTrigger {
		border: 0;
		background-color: $colorSlideshowTrigger;
		border-radius: 50%;
		font-size: 0;
		height: $slideshowTriggerDiameter;
		line-height: 0;
		padding: $base * 0.5;
		text-indent: -10000px;
		width: $slideshowTriggerDiameter;

		&.u14-js-current {
			background-color: $colorSlideshowTriggerActive;
		}
	}

	&--link {
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		color: inherit;
		padding: 0;
		min-width: 0;
		text-align: left;
	}

	&--prominent {
		@include media($columnsBreakpoint) {
			min-width: ($buttonMinWidth * 2 + $base2);
		}
	}

	&--seamless {
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;
		min-width: 0;
	}

	&--secondary {
		background-color: transparent;
	}

	.u14-booking & {
		min-width: 0;
		width: 100%;
	}

	// buttons inside forms
	.u14-form & {
		margin-bottom: $base2;
		margin-left: 0;
		margin-top: $base2;
		min-width: $buttonMinWidth;
		padding: $inputPadding;
	}

	.u14-form > &:first-child {
		margin-top: 0;
	}

	.u14-form__buttons > & {
		margin-left: $base2;
		margin-top: 0;
	}

	.u14-field--search .u14-field__input--text + & {
		flex-grow: 0;
		flex-shrink: 1;
		margin-bottom: 0;
		margin-left: $base2;
		margin-top: 0;
		min-width: 0;

		@include media($columnsBreakpoint) {
			min-width: $buttonMinWidth;
		}
	}

	.u14-field__fields--grid & {
		width: auto;

		@include media($columnsBreakpoint) {
			align-self: flex-end;
			min-width: none;
			width: dynamicColumnSize(1, 3, $gutter);
		}
	}

	.u14-form &--newsletterSubmit {
		order: 4;
		margin-left: $gutter;
		width: calc(100% - 1.5rem);

		@include media($columnsBreakpoint) {
			align-self: flex-start;
			margin-bottom: 0;
			margin-left: $gutter;
			margin-top: $fieldsVSpace;
			order: 2;
			width: dynamicColumnSize(1, 2, $gutter);
		}
	}

	&--trackersAcceptAll,
	&--trackersRejectAll {
		width: staticColumnSize(1, 2, $gutter);

		.u14-trackers.u14-js-confirmMode & {
			display: none;
		}
	}

	&--trackersOptions {
		text-align: center;
		width: staticColumnSize(1, 1, $gutter);
	}

	&--trackersSave {
		width: staticColumnSize(1, 1, $gutter);
	}

	&--trackersOk {
		width: staticColumnSize(1, 1, $gutter);

		.u14-trackers:not(.u14-js-confirmMode) & {
			display: none;
		}
	}

	&--trackersDetails {
		font-weight: bold;
		width: 100%;

		&:focus {
			outline: 1px solid currentColor;
			outline-offset: 2px;
		}
	}

	&__icon {
		.u14-button--trackersDetails & {
			@include ease(transform, $duration2);
			position: relative;
		}

		.u14-button--trackersDetails.u14-js-toggled & {
			transform: rotate(180deg);
		}
	}

	&__label {
		// .u14-button--trackersDetails.u14-js-toggled &--show,
		// .u14-button--trackersDetails:not(.u14-js-toggled) &--hide,
		.u14-trackers.u14-js-expanded .u14-button--trackersOptions &--show,
		.u14-trackers:not(.u14-js-expanded) .u14-button--trackersOptions &--hide {
			display: none;
		}
	}
}
