// the regular version is directly requested in the <head> in order to prefetch the file

@font-face {
	font-family: 'GrowC';
	src:
		url('../fonts/Grow_C.woff2') format('woff2'),
		url('../fonts/Grow_C.woff') format('woff');
}


@font-face {
	font-family: 'FuturaPro'; // Futura Pro Book W05 Regular
	src: url('../fonts/5114183/f0510bea-8331-4877-86fb-37d530f5bd8b.eot?#iefix');
	src:
		url('../fonts/5114183/f0510bea-8331-4877-86fb-37d530f5bd8b.eot?#iefix') format('eot'),
		url('../fonts/5114183/687d7d3d-9468-400c-9ec4-5b92c55c410f.woff2') format('woff2'),
		url('../fonts/5114183/02aa7ba8-c9de-4b07-9e2a-fa05d09c9c07.woff') format('woff'),
		url('../fonts/5114183/2fd37181-2526-4988-a13f-e855ee9cb6eb.ttf') format('truetype');
}
