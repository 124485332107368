
@mixin u14-toggleContainer($apply: true) {
	padding-left: if($apply, $containerPaddingSmall, 0);
	padding-right: if($apply, $containerPaddingSmall, 0);
	margin-left: auto;
	margin-right: auto;
	max-width: if($apply, $containerMaxWidth, none);
	width: 100%;

	@include media($columnsBreakpoint) {
		padding-left: if($apply, $containerPaddingLarge, 0);
		padding-right: if($apply, $containerPaddingLarge, 0);
	}
}
