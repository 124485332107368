.u14-main {
	padding-top: $headerHeightSmall;

	@include media($columnsBreakpoint) {
		padding-top: $headerHeightLarge;
	}


	&.u14-js-covered {
		@include u14-covered();
	}


	.u14-js-pageElevator & {
		padding-top: 0;

		@include media($columnsBreakpoint) {
			padding-top: 0;
		}
	}
}
