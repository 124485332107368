.u14-footer {
	color: $colorAlternative;
	background: $colorBgAlternative;

	&.u14-js-covered {
		@include u14-covered();
	}


	&__contact {
		@include font(footer);

		align-items: flex-start;
		display: flex;
		flex-direction: column;
		padding-top: $base3;

		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(1, 4, $base3);
			margin-left: $base3;
		}
	}


	&__contacts {
		padding-bottom: $base6;

		@include media($columnsBreakpoint) {
			@include dynamicColumns($base3);
		}
	}


	&__content {
		@include u14-container();

		padding-top: $base3;

		@include media($columnsBreakpoint) {
			padding-top: $base5;
			padding-bottom: $base5;
		}
	}


	&__link {
		@include u14-link;

		width: 100%;
		word-wrap: break-word;
	}


	&__subtitle {
		@include font(footer);

		font-weight: normal;
	}


	&__title {
		@include font(footerTitle);

		font-weight: normal;
	}
}
