$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-size: pxToRem(20),
		line-height: 1.58,
		variants: (
			'>small': (
				font-size: pxToRem(20),
				line-height: 1.75,
			)
		)
	),
	baseBigger: (
		font-size: pxToRem(18),
		line-height: 1.58,
		variants: (
			'>small': (
				font-size: pxToRem(24),
				line-height: 1.6,
			)
		)
	),
	title: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(36),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.2,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
	),
	titleBigger: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(48),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.2,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
	),
	subtitle: (
		font-size: pxToRem(20),
		font-weight: bold,
		line-height: 1.6,
	),
	subtitleBigger: (
		font-size: pxToRem(22),
		line-height: 1.6,
	),
	calendar: (
		font-size: pxToRem(16),
		line-height: 1.8,
	),
	calendarDay: (
		font-size: pxToRem(12),
		line-height: 2.67,
	),
	category: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(20),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.58,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
		variants: (
			'>small': (
				font-size: pxToRem(20),
				line-height: 1.75,
			)
		)
	),
	caption: (
		font-size: pxToRem(12),
		line-height: 1.6,
	),
	languageMenu: (
		font-size: pxToRem(18),
		line-height: 48/18,
		variants: (
			'>small': (
				font-size: pxToRem(18),
				line-height: 48/18,
			)
		)
	),
	header: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(14),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.6,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
		variants: (
			'>small': (
				font-size: pxToRem(16),
			)
		)
	),
	mainMenu: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(20),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.38,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
		variants: (
			'>small': (
				font-size: pxToRem(48),
				line-height: 1.2,
			)
		)
	),
	footer: (
		font-size: pxToRem(18),
		line-height: 1.6,
		variants: (
			'>small': (
				font-size: pxToRem(16),
			)
		)
	),
	footerTitle: (
		font-size: pxToRem(24),
		line-height: 1.56,
	),
	field: (
		font-size: pxToRem(16),
		line-height: 1.8,
	),
	fieldDescription: (
		font-size: pxToRem(16),
		line-height: 1.6,
	),
	fieldError: (
		font-size: pxToRem(16),
		line-height: 1.6,
	),
	ugsFeatures: (
		font-family: $fontFamilyAlternative,
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
		font-size: pxToRem(20),
		line-height: 1.58,
		variants: (
			'>small': (
				font-size: pxToRem(20),
				line-height: 1.75,
			)
		)
	),
	sliderTitle: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(48),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.2,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
	),
	sliderSubTitle: (
		font-family: $fontFamilyAlternative,
		font-size: pxToRem(16),
		font-variant-ligatures: none,
		font-weight: normal,
		letter-spacing: $letterspacingTitle,
		line-height: 1.8,
		moz-osx-font-smoothing: grayscale,
		text-transform: lowercase,
		webkit-font-smoothing: antialiased,
	),
	sliderText: (
		font-size: pxToRem(16),
		line-height: 1.6,
	),
	googleMapPopup: (
		font-size: pxToRem(18),
		line-height: 1.3
	)
);
