.u14-image {
	max-width: 100%;

	&--bg {
		height: 100%;
		user-select: none;
		width: 100%;

		.u14-block--facepattern & {
			@include ease(opacity, $facepatternChangeDuration);
			position: absolute;
			opacity: 1;

			&.u14-js-fadeOut {
				opacity: 0;
			}
		}
	}

	.u14-preview & {
		.u14-block--colSpan12 & {
			@include media($columnsBreakpoint) {
				width: staticColumnSize(2, 3, $gutter);
			}
		}
	}

	.u14-block--teaser.u14-block--minHeightBg &,
	.u14-gallery &,
	.u14-block--image & {
		@include ifSupportCustomProperties() {
			@include minAspectRatio(var(--heightRatioPerc));
			flex-wrap: wrap;
		}
	}


	&__caption {
		@include font(caption);
		margin-top: $base;

		.u14-blocks:not(.u14-blocks--nested) > .u14-block--image.u14-block--hSizeFull & {
			@include u14-container();
		}

		.u14-block--teaser .u14-block__figure & {
			bottom: 0;
			left: 0;
			padding: $base;
			position: absolute;
			text-align: left;
			width: 100%;
		}
	}


	&__img {
		max-width: 100%;

		.u14-image--bg & {
			@include objectFit(cover);
			height: 100%;
			user-select: none;
			width: 100%;
		}

		.u14-block--image &,
		.u14-gallery & {
			display: block;
			width: 100%;
			height: 100%;
		}

		.u14-video &,
		.u14-gallery:not(.u14-gallery--layoutTypeJustified) & {
			@include objectFit(cover);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
		}

		.u14-preview & {
			@include objectFit(cover);
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}


	&__imgWrapper {
		max-width: 100%;

		.u14-preview &,
		.u14-gallery:not(.u14-gallery--layoutTypeJustified) & {
			display: block;
			padding-top: (100% / $galleryColumnsImageRatio);
			position: relative;
		}
	}
}
