.u14-infoModal {
	@include fadeOut($duration4);
	background-color: transparent;
	align-items: center;
	display: none;
	left: 0;
	justify-content: center;
	min-height: 100vh;
	position: relative;
	right: 0;
	top: 0;
	z-index: 4;

	@include media($bookingBreakpoint) {
		padding-top: $headerHeightLarge;
	}

	&.u14-js-fixed {
		position: fixed;
	}

	&.u14-js-beforeActive {
		display: flex;
		will-change: opacity, visibility;
	}

	&.u14-js-duringActive {
		@include fadeIn();
	}


	&__content {
		@include u14-container();
		max-width: $infoModalMaxWidth;
		background-color: $colorBgModal;
		padding: $base3;
		height: auto;
	}


	&__text {
		@include font(base);
		text-align: center;
	}


	&__title {
		@include font(title);
		color: $colorHighlight;
		margin-bottom: $base4;
		text-align: center;
	}

	&__actions {
		margin-top: $base3;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media($columnsBreakpoint) {
			flex-direction: row;
		}
	}
}
