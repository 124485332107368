.u14-bookingModal {
	@include fadeOut($duration4);
	background-color: $colorBgModal;
	display: none;
	left: 0;
	min-height: 100vh;
	padding-top: $headerHeightSmall;
	position: relative;
	right: 0;
	top: 0;
	z-index: 3;

	@include media($bookingBreakpoint) {
		padding-top: $headerHeightLarge;
	}

	&.u14-js-fixed {
		position: fixed;
	}

	&.u14-js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.u14-js-duringActive {
		@include fadeIn();
	}


	&__banner {
		@include font(base);
		max-width: $containerMaxWidth;
		padding-bottom: $firstBlockTopSpacing;
		text-align: center;

		@include media($bookingBreakpoint) {
			padding-left: staticColumnPush(3, 12, $gutter);
			padding-right: staticColumnPush(3, 12, $gutter);
		}
	}


	&__content {
		@include u14-container();
		padding-bottom: $blockVerticalSpacing;
		padding-top: $firstBlockTopSpacing;
	}
}
