@mixin u14-fadeIn($delay: 0s, $element: false) {
	@if ($element == false) {
		&[data-u14-intersect] {
			opacity: 0;
			//filter: contrast(2);
		}

		&.u14-js-inside {
			@include ease(opacity filter, $durationOpacityShowAnim, $easeOutQuad, $delay);
			opacity: 1;
			//filter: contrast(1);
		}
	}
	@else {
		#{$element}[data-u14-intersect] & {
			opacity: 0;
			//filter: contrast(2);
		}

		#{$element}.u14-js-inside & {
			@include ease(opacity filter, $durationOpacityShowAnim, $easeOutQuad, $delay);
			opacity: 1;
			//filter: contrast(1);
		}
	}
}
