.u14-mainMenuToggler {
	@include ease(opacity, $duration, $easeOutQuad);
	@include font(header);
	@include u14-call2actions;
	cursor: pointer;
	z-index: 30;


	&__close {
		.u14-mainMenuToggler:not(.u14-js-close) & {
			display: none;
		}
	}

	&__icon {
		@include media($columnsBreakpoint) {
			display: none;
		}
	}


	&__open {
		.u14-mainMenuToggler.u14-js-close & {
			display: none;
		}
	}


	&__text {
		display: none;

		@include media($columnsBreakpoint) {
			display: block;
		}
	}
}
