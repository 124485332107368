.u14-languageMenu {
	@include font(languageMenu);
	@include u14-container();

	align-items: flex-start;
	bottom: 1em;
	display: flex;
	flex-direction: column;
	left: 50%;
	position: absolute;
	transform: translate(-50%);


	&__link {
		@include u14-call2actions;
		@include font(languageMenu);

		color: $colorHighlightDisabled;
		flex-grow: 0;
		flex-shrink: 1;

		&--active {
			color: $colorAlternative;
		}
	}
}
