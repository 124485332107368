$colorHighlight: #b6a073;
$colorHighlightDisabled: rgba($colorHighlight, 0.4);

$colorBase: #000000;
$colorBgBase: #ffffff;
$colorInverted: $colorBgBase;
$colorBgInverted: $colorBase;
$colorAlternative: $colorHighlight;
$colorBgAlternative: #f5f1ea;

$colorBgModal: $colorBgBase; //#CCCCCC;
$colorBgTrackerGroupHeader: #efefef;

$colorInput: $colorAlternative;
$colorBgInput: transparent;
$colorInputBorder: $colorAlternative;
$colorError: #ff0000;

$colorLink: $colorAlternative;
$colorLinkHover: $colorBase;

$colorBaseTextOnImageContrast: rgba(245, 241, 234, 0.4);

$colorHeaderBorder: $colorAlternative;

$colorSlideshowTriggerActive: $colorBase;
$colorSlideshowTrigger: $colorHighlight;

$colorBgModalCover: $colorBgBase;
